<div class="wrapper">
  <div class="left">
    <p>{{ forecast.date | date: 'EEEE d' }}</p>
  </div>
  <div class="right">
    <img width="30px" src="assets/weather-icons/{{ forecast.icon }}.svg" />
    <p>{{ forecast.temperatureMin | number: '1.0-0' }}°c</p>
    <p>{{ forecast.temperatureMax | number: '1.0-0' }}°c</p>
  </div>
</div>
