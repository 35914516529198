<div class="wrapper">
  <span>
    <h2>{{ city.name }}</h2>
    <p>{{ forecast.temperature | number:'1.0-0' }}°c</p>
  </span>
  <img
    width="150px"
    src="assets/weather-icons/{{ forecast.icon }}.svg"
  />
</div>
