import { Component, OnInit, Renderer2 } from '@angular/core';
import { WeatherService } from '@services/weather/weather.service';
import { City } from 'app/core/models/city';
import { HelperService } from '@services/helper/helper.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  city: City;

  weather: any;

  constructor(
    private weatherService: WeatherService,
    private helperService: HelperService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.getWeather();
  }

  // Private methods

  /**
   * Gets weather forecast
   */
  private getWeather() {
    // Test purpose
    this.city = new City('FR', 'Toulouse', 43.604259, 1.44367);
    this.weatherService.getWeather(this.city).subscribe(
      (forecast) => {
        // Add class to change body background if it is night time
        // Night time between 6pm and 6am
        const currentHour = this.helperService
          .millisecondsToDate(forecast.currently.time)
          .getHours();
        if (currentHour >= 18 || currentHour < 6) {
          this.renderer.addClass(document.body, 'night');
        } else {
          this.renderer.removeClass(document.body, 'night');
        }
        this.weather = forecast;
        this.weather.daily.data = this.weather.daily.data.slice(
          1,
          this.weather.daily.data.length - 1
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
