import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateComponent } from './components/date/date.component';
import { WeatherComponent } from './components/weather/weather.component';
import { HourlyComponent } from './components/hourly/hourly.component';
import { ForecastComponent } from './components/forecast/forecast.component';
import { DayForecastComponent } from './components/day-forecast/day-forecast.component';

@NgModule({
  declarations: [
    DateComponent,
    WeatherComponent,
    HourlyComponent,
    ForecastComponent,
    DayForecastComponent,
  ],
  imports: [CommonModule],
  exports: [
    DateComponent,
    WeatherComponent,
    HourlyComponent,
    ForecastComponent,
  ],
})
export class SharedModule {}
