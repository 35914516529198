import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  constructor() {}

  /**
   * Converts milliseconds to date
   * @param time milliseconds
   */
  millisecondsToDate(time: number): Date {
    return new Date(time * 1000);
  }
}
