import { Component, OnInit, Input } from '@angular/core';
import { HelperService } from '@services/helper/helper.service';

@Component({
  selector: 'app-hourly',
  templateUrl: './hourly.component.html',
  styleUrls: ['./hourly.component.scss']
})
export class HourlyComponent implements OnInit {
  @Input() hourlyForecast: any;

  displayedData: any[];

  constructor(private helperService: HelperService) {}

  ngOnInit(): void {
    this.displayedData = this.hourlyForecast.data.slice(1, 5);
    this.displayedData.forEach(data => {
      data.date = this.helperService.millisecondsToDate(data.time);
    });
  }
}
