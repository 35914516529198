import { Component, OnInit, Input } from '@angular/core';
import { HelperService } from '@services/helper/helper.service';

@Component({
  selector: 'app-forecast',
  templateUrl: './forecast.component.html',
  styleUrls: ['./forecast.component.scss'],
})
export class ForecastComponent implements OnInit {
  @Input() forecast: any;

  constructor(private helperService: HelperService) {}

  ngOnInit() {
    this.forecast.forEach((day) => {
      day.date = this.helperService.millisecondsToDate(day.time);
    });
  }
}
