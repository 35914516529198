export class City {
  country: string;
  name: string;
  lat: number;
  lng: number;

  constructor(country: string, name: string, lat: number, lng: number) {
    this.country = country;
    this.name = name;
    this.lat = lat;
    this.lng = lng;
  }
}
