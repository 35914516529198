import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { City } from '../../models/city';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

const CORS = 'https://cors-anywhere.herokuapp.com/';
const API = environment.API;

@Injectable({
  providedIn: 'root'
})
export class WeatherService {
  constructor(private http: HttpClient) {}

  /**
   * Get weather
   * @param city input city for weather
   */
  getWeather(city: City): Observable<any> {
    return this.http.get<any>(
      CORS + API + '/' + city.lat + ',' + city.lng + '?units=ca'
    );
  }
}
